<template>
  <div class="card-boutique">
    <div class="card-block card-boutique__title">
      <h2 class="title">{{boutiqueInfo.city}}</h2>
    </div>
    <div class="card-block card-boutique__description">
      <div class="street-name">
        <span class="text">{{boutiqueInfo.address}}</span>
      </div>
    </div>
    <div class="card-block card-boutique__content">
      <div class="store-info-column">
        <div class="store-info-column-col phone-number">
          <span class="label">{{ $t('storeLocator.storeDetails.phone') }}</span>
          <a :href="`tel:${boutiqueInfo.phone}`" class="text">{{boutiqueInfo.phone}}</a>
        </div>
        <div class="store-info-column-col  email">
          <span class="label">{{ $t('storeLocator.storeDetails.email') }}</span>
          <span class="text">
            <a :href="`mailto:${boutiqueInfo.email}`" class="bcm-link bcm-link-underline-primary text-inherit">{{boutiqueInfo.email}}</a>
          </span>
        </div>
      </div>
      <div class="store-info-column">
        <div class="store-info-column-col working-hours" v-if="boutiqueInfo.working_hours && viewWorkingHours">
          <span class="label">{{ $t('storeLocator.storeDetails.workingHours') }}</span>
          <div class="wh-list" v-for="(wh, index) in boutiqueInfo.working_hours" :key="index">
            <span class="label">{{index}} </span>
            <span class="wh">{{wh.from}} - {{wh.to}}</span>
          </div>
        </div>
        <div class="store-info-column-col directions-cta">
          <a :href="calcRouteUrl" target="_blank" class="directions bcm-link text-inherit" v-if="calcRouteUrl">
            <span class="label">&nbsp;</span>
            <span>{{ $t('storeLocator.storeDetails.getDirections') }}</span>
            <svg><use xlink:href="#arrow-link"></use></svg>
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="card-block card-boutique__footer">
      <div class="store-info-column ctas">
        <router-link :to="{ name: 'Boutique', params: {store_code: boutiqueInfo.code}}"  class="bcm-link bcm-link-secondary" v-if="viewDetail">{{ $t('view-detail') }}</router-link>
        <button class="btn btn-none bcm-link bcm-link-secondary" v-if="viewMap" @click.prevent="openModalBoutique">{{ $t('view-map') }}</button>
        <slot name="footer-ctas"><span></span></slot>
      </div>
    </div> -->
  </div>
</template>

<script>
import ImageGallery from '@/components/Product/Image.vue'

export default {
  name: 'CardBoutique',
  props: {
    boutiqueInfo: {
      type: Object | Array,
      required: true
    },
    viewImage: {
      type: Boolean,
      default: true
    },
    viewDetail: {
      type: Boolean,
      default: true
    },
    viewMap: {
      type: Boolean,
      default: true
    },
    viewWorkingHours: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calcRouteUrl () {
      if (this.boutiqueInfo) {
        return `//maps.google.com/maps?saddr=current+location&daddr=${this.boutiqueInfo.country},${this.boutiqueInfo.region},${this.boutiqueInfo.city},${this.boutiqueInfo.address}`
      }
      return null
    }
  },
  methods: {
    openModalBoutique () {
      return this.$emit('viewMap', this.boutiqueInfo)
    }
  },
  components: {
    ImageGallery
  }
}
</script>
